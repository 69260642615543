

















































import {
  AiAssistantContext,
  SubUseCase,
  UseCase,
  UseCaseView
} from '@/models/AiAssistantContext'
import AIService from '@/services/AIService'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { alert } from '@/components/dialogs'

@Component({})
export default class SelectFunctions extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ required: true }) ctx!: AiAssistantContext
  @PropSync('ctxView', { required: true }) view!: UseCaseView
  @PropSync('generatingLayout', { required: true }) generating!: boolean
  @PropSync('layoutComponent', { required: true }) layoutCmp!: any
  @Prop({ required: true }) useCase!: UseCase
  @Prop({ required: true }) subCases!: SubUseCase[]

  aiService: AIService | null = null

  generatingFunctions = 5

  get loaderType() {
    return Array(this.generatingFunctions).fill('list-item-avatar').join(',')
  }

  mounted() {
    this.aiService = new AIService(this.environmentId)
    console.log(this.environmentId, this.aiService)
    if (!this.view.functions?.length) {
      this.generateFunctions()
    } else {
      this.generatingFunctions = 0
    }
  }

  async generateFunctions() {
    if (!this.aiService) return
    this.generatingFunctions = 5
    try {
      if (!this.view.functions) this.view.functions = []
      const selectedFunctions = this.view.functions.filter((fn) => fn.value)
      const n = selectedFunctions.length
      const promptParts = [
        `Nombre del software: ${this.ctx.environmentName}`,
        `Descripción del software: ${this.ctx.environmentDescription}`,
        `Caso de uso operacional: ${this.useCase.text}`,
        `Vista: ${this.view.name}`,
        `Ruta: ${this.view.path}`,
        'Sub-procesos de la vista:',
        this.subCases.map((sc) => `- ${sc.text}`).join('\n'),
        `Escribe ${
          n + 5
        } posibles funcionalidades para esta vista (una por línea):`,
        ...selectedFunctions.map((fn) => `- ${fn.name}`),
        ` -`
      ]
      const response = await this.aiService.completions({
        model: 'text-davinci-003',
        prompt: promptParts.join('\n'),
        temperature: 0.7,
        max_tokens: 1500
      })
      const fns = response.choices[0].text
        .split('\n')
        .map((fn: string) => {
          return fn.replace(/^\s*-\s*/g, '').replace(/\.?\s*$/g, '')
        })
        .filter((u: string) => u)
      this.view.functions = selectedFunctions
      this.view.functions.push(
        ...fns.map((fn: string) => ({ name: fn, value: false }))
      )
    } catch (e) {
      console.error(e)
      alert('Se produjo un error generando la lista de funcionalidades')
    } finally {
      this.generatingFunctions = 0
    }
  }

  async generateLayout() {
    if (!this.aiService) return
    this.generating = true
    try {
      if (!this.view.functions) this.view.functions = []
      const selectedFunctions = this.view.functions.filter((fn) => fn.value)
      const promptParts = [
        `Nombre del software: ${this.ctx.environmentName}`,
        `Descripción del software: ${this.ctx.environmentDescription}`,
        `Caso de uso operacional: ${this.useCase.text}`,
        `Vista: ${this.view.name}`,
        `Ruta: ${this.view.path}`,
        'Sub-procesos de la vista:',
        this.subCases.map((sc) => `- ${sc.text}`).join('\n'),
        'Funcionalidades de la vista:',
        ...selectedFunctions.map((fn) => `- ${fn.name}`),
        'Librería: Vuetify 2'
      ]
      this.layoutCmp = await this.aiService.vue({
        model: 'code-davinci-002',
        prompt: promptParts.join('\n'),
        temperature: 0.7,
        max_tokens: 1500
      })
    } catch (e) {
      console.error(e)
      alert('Se produjo un error generando el layout')
    } finally {
      this.generating = false
    }
  }
}
